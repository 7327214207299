import React from "react";
import { Row, Col } from "antd";
import Link from "next/link";
import { WebRoutes } from "@/src/routes";

const AboutUsSection = () => {
  return (
    <section id="about" className="about">
      <div className="px-4">
        <Row>
          <Col md={10} lg={10}>
            <div className="video-box" data-aos="fade-right" />
          </Col>
          <Col md={14} lg={14}>
            <div className="icon-boxes flex flex-col items-stretch justify-center py-12 lg:px-12" data-aos="fade-left">
              <div className="section-title pb-6!" data-aos="fade-in">
                <h2>About Us</h2>
                <p>What do we do?</p>
              </div>
              <p>
                Founded in 1991 by Mr. Madan Sharma, Indo Aircon Engineers has come a long way serving people by more than <b>30+ years</b> of experience with experienced well-trained professionals. Our company has successfully covered many projects and we ensure the client satisfaction. We offer services in water and air-cooled chiller, central air-conditioning plants, ductable air-conditioners, all types of compressor repair, all type of window and split air-conditioners maintenance and amc&apos;s, panel ac, air dryer, and all sorts of commercial air-conditioning maintenance. We hope you will enjoy our services as much as we enjoy offering them to you. If you have any questions or comments, please don&apos;t hesitate to contact us.
              </p>

              <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon">
                  <i className="icofont-ui-calendar" />
                </div>
                <h6 className="title">
                  <Link href={WebRoutes.HOME}>Annual Maintenance Contract</Link>
                </h6>
                <p className="description">
                  We at Indo Aircon Engineers believe in providing our valued customers the best service and quality performance. Striving to keep our commitments and to ensure best product performance, we offer to you exciting <b>Annual Maintenance Contract</b> to cover the service support for all your products.
                </p>
              </div>

              <div className="icon-box" data-aos="zoom-in" data-aos-delay="200">
                <div className="icon">
                  <i className="icofont-snow" />
                </div>
                <h6 className="title">
                  <Link href={WebRoutes.HOME}>Completing All Air Conditioning Needs</Link>
                </h6>
                <p className="description">Indo Aircon Engineers is one such company that has a vision to be leader of their fields and we feel we have that strength and power to satisfy all our clients demands. You can count on us for all your home and commercial Air-Conditioning needs.</p>
              </div>

              <div className="icon-box" data-aos="zoom-in" data-aos-delay="300">
                <div className="icon">
                  <i className="icofont-repair" />
                </div>
                <h6 className="title">
                  <Link href={WebRoutes.HOME}>24X7 Customer Support</Link>
                </h6>
                <p className="description">For all kind of support and maintenence service, We are always ready to assist you, 24 hours a day, 7 days a week. It’s good to know Indo Aircon Engineers are always just a phone call away!</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default AboutUsSection;
