import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URL } from "@/src/utils/constant";

export const useGetClient = () => {
  return useQuery({
    queryKey: ["client"],
    queryFn: async () => {
      const response = await axios.get(API_URL.CLIENTS);
      return response?.data?.data;
    },
  });
};

export const useCreateClient = () => {
  return useMutation({
    mutationFn: async (body) => {
      const response = await axios.post(API_URL.CLIENTS, body);
      return response?.data;
    },
  });
};

export const useDeleteClient = () => {
  return useMutation({
    mutationFn: async ({ id, publicId }) => {
      const response = await axios.delete(API_URL.CLIENTS, { data: { id, publicId } });
      return response?.data;
    },
  });
};
