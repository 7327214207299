"use client";
import React, { useEffect } from "react";
import Image from "next/image";
import { Carousel, Col, Row } from "antd";
import { useGetClient } from "@/src/hooks/useClient";
import AboutUsSection from "@/src/app/(website)/about/AboutUsSection";
import useGlobalContext from "@/src/hooks/useGlobalContext";

import workersImg from "@/src/public/img/workers.png";

const Home = () => {
  const { isLoading: clientLoading, data: clientData } = useGetClient();
  const { setLoading } = useGlobalContext();

  useEffect(() => setLoading(clientLoading), [clientLoading, setLoading]);

  const carouselConfig = { arrows: true, autoplay: false, infinite: true, draggable: true, fade: true, effect: "fade", waitForAnimate: true };

  return (
    <>
      <section id="hero" className="homeHero">
        <Carousel {...carouselConfig}>
          <div>
            <div className="carousel-container" style={{ backgroundImage: `url(https://res.cloudinary.com/dzdcdfwww/image/upload/v1742064842/banner-1_by6yl7.jpg)` }}>
              <h2 className="animate__animated animate__fadeInDown">
                <small>Welcome to</small>
                <div>Indo Aircon Engineers</div>
              </h2>
              <p className="animate__animated animate__fadeInUp">We welcome the opportunity to work with you and we want to have long-term relationships with customers in which trust and understanding are mutual. Assuring you our best services at all times.</p>
              <a href="#about" className="btn-get-started animate__animated animate__fadeInUp scrollto">
                About Us
              </a>
            </div>
          </div>
          <div>
            <div className="carousel-container" style={{ backgroundImage: `url(https://res.cloudinary.com/dzdcdfwww/image/upload/v1742064843/banner-2_a8pmzu.jpg)` }}>
              <h2 className="animate__animated animate__fadeInDown">
                <small>Quality Service</small>
                <div>Best Services, Client Satisfaction</div>
              </h2>
              <p className="animate__animated animate__fadeInUp">You can contact our customer care for any queries. We are eco-friendly , clean, quality work. For all our customers we have additional benefits.</p>
              <a href="#about" className="btn-get-started animate__animated animate__fadeInUp scrollto">
                About Us
              </a>
            </div>
          </div>
          <div>
            <div className="carousel-container" style={{ backgroundImage: `url(https://res.cloudinary.com/dzdcdfwww/image/upload/v1742064842/banner-3_xawsxw.jpg)` }}>
              <h2 className="animate__animated animate__fadeInDown">
                <small>Expert Service</small>
                <div>We are Professionals</div>
              </h2>
              <p className="animate__animated animate__fadeInUp">We have well-trained employees and latest state of art machinery. We cherish to serve our Customers in the best possible manner. You can connect with us for any kinds of issues related to your air conditioner.</p>
              <a href="#about" className="btn-get-started animate__animated animate__fadeInUp scrollto">
                About Us
              </a>
            </div>
          </div>
        </Carousel>
      </section>

      <main id="main">
        <AboutUsSection />

        <section id="details" className="details">
          <div className="container">
            <Row>
              <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 10, order: 2 }} lg={{ span: 10, order: 2 }} data-aos="fade-left">
                <Image src={workersImg} className="h-auto max-w-full" loading="lazy" alt="workers" />
              </Col>
              <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 14, order: 1 }} lg={{ span: 14, order: 1 }} data-aos="fade-up" className="pt-4">
                <div className="content pt-4">
                  <div className="section-title" data-aos="fade-up">
                    <h2>Best Services</h2>
                    <p>Why choose us?</p>
                  </div>
                  <p data-aos="fade-in">When you choose us, you choose to give value to your money, you choose to care for yourself, because we are committed to provide the services you need as effortlessly as possible.</p>
                  <ul>
                    {/* <!-- <li data-aos="fade-left" data-aos-delay="50">ISO Certified Company</li> --> */}
                    <li data-aos="fade-left" data-aos-delay="100">
                      Experienced Team
                    </li>
                    <li data-aos="fade-left" data-aos-delay="150">
                      360 Degree Approach
                    </li>
                    <li data-aos="fade-left" data-aos-delay="200">
                      Intelligent Engineering
                    </li>
                    <li data-aos="fade-left" data-aos-delay="250">
                      Value Delivering Premises
                    </li>
                    <li data-aos="fade-left" data-aos-delay="300">
                      Focused and In-depth Knowledge
                    </li>
                    <li data-aos="fade-left" data-aos-delay="350">
                      Customized solution for each requirement
                    </li>
                    <li data-aos="fade-left" data-aos-delay="400">
                      Proven & established track record in HVAC field
                    </li>
                    <li data-aos="fade-left" data-aos-delay="450">
                      Strong and long lasting relationships with our Vendors and Clients.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section id="client" className="client">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Clientele</h2>
              <p>Some of Our Valuable Clients</p>
            </div>

            <Row gutter={[30, 30]}>
              {!clientLoading &&
                clientData
                  ?.sort((a, b) => a.sequence - b.sequence)
                  ?.map((client, i) => (
                    <Col xs={24} sm={24} md={8} lg={6} key={client?.id}>
                      <div className="icon-box" data-aos="zoom-in" data-aos-delay={i * 50}>
                        <Image src={client?.url} alt={`client-${client?.sequence}`} width={115} height={90} />
                      </div>
                    </Col>
                  ))}
            </Row>
          </div>
        </section>
      </main>
    </>
  );
};

export default Home;
